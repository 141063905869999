html {
  max-height: 100%;
  overflow: auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="checkbox" i] {
  background-color: #ADE82F;
}



/* @-webkit-keyframes draw-checkbox-28 {
  0% {
    stroke-dashoffset: 33;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes draw-checkbox-28 {
  0% {
    stroke-dashoffset: 33;
  }

  100% {
    stroke-dashoffset: 0;
  }
} */

.loader {
  width: 250px;
  margin: 0 auto;
  border-radius: 10px;
  height: 10px;
  position: relative;
  padding: 0px;
  background-color: #808080;
}

.loader:before {
  content: '';
  border: 1px solid #808080;
  border-radius: 10px;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.loader .loaderBar {
  position: absolute;
  border-radius: 10px;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(90deg, #ADE82F 0%, #E6F728 100%);
  width: 0;
  animation: borealisBar 2s linear infinite;
}

@keyframes borealisBar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }

  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }

  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }

  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}

.spin-loader {
  width: 40px;
  height: 40px;
  border: 5px solid #ADE82F;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.checkbox-wrapper-40 {
  --borderColor: #1b1b19;
  --borderWidth: .1em;
}

.checkbox-wrapper-40 label {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.checkbox-wrapper-40 input[type=checkbox] {
  -webkit-appearance: none;
  appearance: none;
  vertical-align: middle;
  background: #fff;
  font-size: 1.2em;
  border-radius: 0.125em;
  display: inline-block;
  border: var(--borderWidth) solid var(--borderColor);
  width: 1em;
  height: 1em;
  position: relative;
}

.checkbox-wrapper-40 input[type=checkbox]:before,
.checkbox-wrapper-40 input[type=checkbox]:after {
  content: "";
  position: absolute;
  background: var(--borderColor);
  width: calc(var(--borderWidth) * 3);
  height: var(--borderWidth);
  top: 50%;
  left: 20%;
  transform-origin: left center;
}

.checkbox-wrapper-40 input[type=checkbox]:before {
  transform: rotate(45deg) translate(calc(var(--borderWidth) / -2), calc(var(--borderWidth) / -2)) scaleX(0);
  transition: transform 200ms ease-in 200ms;
}

.checkbox-wrapper-40 input[type=checkbox]:after {
  width: calc(var(--borderWidth) * 5);
  transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2)) scaleX(0);
  transform-origin: left center;
  transition: transform 200ms ease-in;
}

.checkbox-wrapper-40 input[type=checkbox]:checked:before {
  transform: rotate(45deg) translate(calc(var(--borderWidth) / -2), calc(var(--borderWidth) / -2)) scaleX(1);
  transition: transform 200ms ease-in;
}

.checkbox-wrapper-40 input[type=checkbox]:checked:after {
  width: calc(var(--borderWidth) * 5);
  transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2)) scaleX(1);
  transition: transform 200ms ease-out 200ms;
}

.checkbox-wrapper-40 input[type=checkbox]:focus {
  outline: calc(var(--borderWidth) / 2) dotted rgba(0, 0, 0, 0.25);
}

.pintura-editor {
  outline-color: white !important;
}

.carousel-onboarding {
  padding-inline: 0;
}

.popover-arrow-container {
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.25);;
}

.popover-arrow {
  bottom: -15px !important;
  opacity: 1 !important;
}